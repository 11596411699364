@tailwind base;
@tailwind components;
@tailwind utilities;


nav > a {
  color: black;
  float: right;
  outline: solid red;
  
  
}

body{
  background-color: rgb(180, 231, 245);
  font-family: 'Karla', monospace;
  margin: 0;
  align-items: center;
  
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  
}

div {
  display: block;
  /* outline: solid green; */
}

.main {
  /* display: flex; */
  background-color: rgba(255, 255, 255, 0.975);
  /* align-items: center; */
  padding: 3rem 1.5rem;
  /* margin-left: 20rem;
  margin-right: 20rem;
  margin-top: 15rem; */
  overflow: auto;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  /* outline: solid red; */
}
  




.info {
  font-size: 1em;
  font-weight: 100;
  color: rgb(56, 56, 56);
  /* outline: solid red; */
  float: left;
  
  
}

.title {
  margin-bottom: -.25em;
  
}

.subtitle {
 margin-left: .5em;
 
}







a {
  color: rgb(58, 181, 181);
  text-decoration: none;
}

a::hover {
  text-decoration: underline;
  color: rgb(34, 107, 107);

}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */




